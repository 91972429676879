<template>
    <div class="PurchaseOrderDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="机构">
                                <el-input placeholder="" v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="配送中心">
                                <el-input placeholder="" v-model="form.repoName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input placeholder="" v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input placeholder="" v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
        </el-card>
        <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.purchase.order.review'" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                show-summary
                max-height="450"
            >
                <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column label="采购价" width="100" prop="showPrices" v-if="showInPrice()">
                    <template slot-scope="scope">
                        {{ scope.row.showPrices }}
                    </template>
                </el-table-column>
                <el-table-column label="赠品数量" width="100" prop="giftCounts">
                    <template slot-scope="scope">
                        {{ scope.row.giftCounts }}
                    </template>
                </el-table-column>

                <template v-if="deptEnabledBigUnit">
                    <el-table-column label="包装因子" width="100" prop="packFactors">
                        <template slot-scope="scope">
                            {{ scope.row.packFactors }}
                        </template>
                    </el-table-column>
                    <el-table-column label="采购箱数" min-width="170" prop="bigUnitCounts" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.bigUnitCounts }}
                        </template>
                    </el-table-column>
                    <el-table-column label="散装数量" min-width="170" prop="bulkCounts" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.bulkCounts }}
                        </template>
                    </el-table-column>
                </template>
                <el-table-column label="采购数" width="100" prop="counts">
                    <template slot-scope="scope">
                        {{ scope.row.counts }}
                    </template>
                </el-table-column>

                <el-table-column label="采购金额" width="120" prop="purchaseShowMoney" v-if="showInPrice()">
                    <template slot-scope="scope">
                        {{ scope.row.purchaseShowMoney }}
                    </template>
                </el-table-column>
                <el-table-column label="总计数量" width="100" prop="totalCounts" />
                <el-table-column label="生产日期" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.produceTimes }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import MoneyUtils from 'js/MoneyUtils';
import PickupReturnAudit from 'components/PickupReturnAudit';

export default {
    name: 'PurchaseOrderDetail',
    components: { PickupReturnAudit },

    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: '/purchase/apply',
            deptEnabledBigUnit: false,
            form: {
                createTime: '',
                creator: '',
                deptName: '',
                repoName: '',
                deptCode: '',
                repoCode: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.purchaseOrderApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            //是否启用大单位权限
            this.deptEnabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                this.form.deptCode,
                'dept.biz.goods.allow.bigUnit'
            );
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
        })();
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl);
        },
        detailParamPropertyArrForShow() {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('produceTimes', 'produceTimes', (produceTimes) => {
                    return this.formatDate(new Date(produceTimes));
                }),
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
            if (this.deptEnabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        //已保存的数据中的包装因子直接展示，不考虑配送中心是否开启大单位权限
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseShowMoney', (purchaseShowMoney, detailParam) => {
                    return this.decimal(detailParam.counts)
                        .mul(detailParam.showPrices || 0)
                        .toNumber();
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(this.computeCount(detailParam)).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        computeCount(detailParam) {
            let counts;
            if (this.hasBigUnitPrivilege) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        rendHeader1(h, { column }) {
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    h('prompt-message', {
                        props: { messages: ['采购总数 = 采购箱数 * 包装因子 + 散装数量'] },
                    }),
                ]
            );
        },
        rendHeader2(h, { column }) {
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    h('prompt-message', {
                        props: { messages: ['采购金额 = 采购价 * 采购总数'] },
                    }),
                ]
            );
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
